import React from 'react'
import styled from 'styled-components'
import { ReUsePtag } from './ReUsePtag'
// import { ReuseImgTag } from './ReuseImgTag'
import { ReUseHOneTag } from './ReUseHOneTag'
import { DemoRequestButton } from './DemoRequestButton'
import { LinkHubspot } from './LinkHubspot'
import { Button } from './Button'

const AppHomeBookDemoWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  height: auto;
  width: 95vw;
  margin-top: 70px;
  margin-bottom: 70px;

  .CompanyHomeBannerSection {
    height: 40vw;
    display: flex;
    justify-content: center; /* Centers the content horizontally */
    align-items: center; /* Centers the content vertically */
    flex-direction: column; /* Stack content for better responsiveness */
    text-align: center;
    margin: 0 auto; /* Centers the section horizontally */
    padding: 50px 20px; /* Add padding for mobile */
    background-image: url('https://storage.googleapis.com/raindroppublic/website_data/Home_news_BG.jpg');
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    border-radius: 42px;
    @media (max-width: 1090px) {
      height: 60vw;
    }
  }

  .CompanyHomeHeading {
    width: auto;
    text-align: center;
    margin-top: 55px;
    font-size: 66px;
    color: #1f1f1f;
    @media (max-width: 1650px) {
      margin-top: 55px;
    }
    @media (max-width: 1090px) {
      padding-top: 35px;
    }
    @media (max-width: 480px) {
      font-size: 42px;
    }
    @media (max-width: 1090px) {
      font-size: 1.5rem;
    }
  }

  .HomeDemoBannerPara {
    width: 100vw;
    text-align: center;
    color: #5f5f5f;
    font-size: 1rem;
    @media (min-width: 1650px) {
      font-size: 1.1rem;
    }
    @media (max-width: 1090px) {
      font-size: 0.8rem;
      padding: 8px 12px 8px 12px;
    }
  }

  .HomeBannerImageSection {
    width: 50%;
    @media (max-width: 1090px) {
      width: 100%;
    }
  }

  .CompanyHomeBannerContentSection {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centers items horizontally */
    justify-content: center; /* Centers items vertically */
    width: 100%;
    max-width: 1200px; /* Limit the width of content */
    margin: 0 auto; /* Centers content within the parent */
    text-align: center;
  }

  .HomeDemoButton {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    margin-top: 24px;
    flex-wrap: wrap;
  }
  .CompanyHomeHeading,
  .HomeDemoBannerPara {
    margin: 0 auto;
    max-width: 1920px; /* Restrict text width for readability */
    padding-bottom: 10px;
  }
`

export const AppHomeBookDemo = () => {
  return (
    <AppHomeBookDemoWapper>
      <div className="CompanyHomeBannerSection">
        <div className="CompanyHomeBannerContentSection">
          <ReUseHOneTag Heading="Let's get more productive" HeadingStyle="CompanyHomeHeading" />
          <ReUsePtag
            para="Learn how you can benefit from Raindrop the AI-Powered Platform Driving Cost Savings."
            paraStyle="HomeDemoBannerPara "
          />
          <div className="HomeDemoButton">
            <LinkHubspot to="https://share.hsforms.com/15ZhMdunrTji6Z5_wWjrDyA4702y">
              <Button cta="Get Started" label="Get Started" type="submit" className="demobutton" />
            </LinkHubspot>
            <DemoRequestButton label="Talk To Us" />
          </div>
        </div>
        {/* <div className="HomeBannerImageSection">
          <ReuseImgTag
            ImageStyle="companyHomeBannerImage"
            ImagePath="/img/partners-banner.png"
            AltName="Company-partners-Banner-image"
          />
        </div> */}
      </div>
    </AppHomeBookDemoWapper>
  )
}
